import React, { Component } from "react";
import  { Link } from "react-router-dom";
class ProductsBanner extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <Link to="/contact" className="text-right white"><span className="yellow">Get a Quote →</span></Link>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Banner</h2>
              <p>Shop <a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=banner" target="_blank">Banner online</a> products here</p>
              <a href="https://www.bannerengineering.com/us/en.html" target="_blank"><img className="product-logo" src="/images/brands/bw-banner.jpg" alt="Banner" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Sensors<span className="underline" /></h3>
                  <ul>
                    <li>Photoelectric sensors</li>
                    <li>Fiber optic sensors</li>
                    <li>Laser sensors</li>
                    <li>Ultrasonic sensors</li>
                    <li>Measurement/inspection sensors</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Vision Sensors and Vision Lighting<span className="underline" /></h3>
                  <ul>
                    <li>PresencePLUS sensors</li>
                    <li>iVue vision sensors</li>
                    <li>Area lights</li>
                    <li>Linear array lights</li>
                    <li>Tubular fluorescent lights</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Lighting and Indicators<span className="underline" /></h3>
                  <ul>
                    <li>Task lights</li>
                    <li>Traffic lights</li>
                    <li>Tower lights</li>
                    <li>Multi-function EZ-lights</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Machine Safety Products<span className="underline" /></h3>
                  <ul>
                    <li>Safety light curtains (Type 4 and 2)</li>
                    <li>Safety interlock switches</li>
                    <li>Safety controllers and modules</li>
                    <li>Emergency stop devices</li>
                    <li>Safety laser scanners (Type 3)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Wireless Sensor Networks<span className="underline" /></h3>
                  <ul>
                    <li>SureCross performance gateways, nodes and MultiHop radios</li>
                    <li>DX80 gateways and nodes</li>
                    <li>Data radios</li>
                    <li>Solar power solutions</li>
                    <li>FlewPower sensors</li>
                    <li>DX70 Point to Point</li>
                    <li>DX85 Modbus RTU remote I/O</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsBanner;
