import React, { Component } from "react";
import  { Link } from "react-router-dom";
class ProductsDanFoss extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <Link to="contact" className="text-right white"><span className="yellow">Get a Quote →</span></Link>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Danfoss</h2>
              <p>Please visit <a href="https://www.danfoss.com/en-us/products/dds/" target="_blank">Danfoss online</a>                to view their full line of products</p>
              <a href="https://www.danfoss.com/en-us/products/dds/" target="_blank"><img className="product-logo" src="/images/brands/bw-danfoss.jpg" alt="Danfoss" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Industrial AC Drives<span className="underline" /></h3>
                  <ul>
                    <li>VLT MICRO</li>
                    <li>VLT 2800</li>
                    <li>VLT 4000</li>
                    <li>VLT 5000</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Soft Starters<span className="underline" /></h3>
                  <ul>
                    <li>DMS 300 Motor Switch</li>
                    <li>MCD 201</li>
                    <li>MCD 202</li>
                    <li>MCD 3000</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Motor Control Products<span className="underline" /></h3>
                  <ul>
                    <li>CI-Tronic Electronic Controls</li>
                    <li>Control &amp; Signaling Devices</li>
                    <li>Contactors</li>
                    <li>Relays</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Additional Motion Controls<span className="underline" /></h3>
                  <ul>
                    <li>VLT 5000 FLUX</li>
                    <li>VLT 6000</li>
                    <li>VLT 8000</li>
                    <li>AutomationDrive FC300</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsDanFoss;
