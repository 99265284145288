import React, { Component } from "react";
import  { Link } from "react-router-dom";
class ProductsWeg extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <Link to="/contact" className="text-right white"><span className="yellow">Get a Quote →</span></Link>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>WEG Electric Motors</h2>
              <p>Please visit <a href="http://www.weg.net/us/Products-Services/Electric-Motors" target="_blank">WEG online</a>                to view their full line of products</p>
              <a href="http://www.weg.net/us/Products-Services/Electric-Motors" target="_blank"><img className="product-logo" src="/images/brands/bw-weg.jpg" alt="WEG" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Electric Motors<span className="underline" /></h3>
                  <ul>
                    <li>Severe Duty and General Purpose: High Efficiency &amp; NEMA Premium</li>
                    <li>Crusher Duty Motors</li>
                    <li>IEEE 841™ Motors</li>
                    <li>Definite Purpose Motors</li>
                    <li>Explosion Proof</li>
                    <li>Fractional Motors</li>
                    <li>IEC Tru-Metric Motors</li>
                    <li>Pump Motors</li>
                    <li>HGF Severe Duty and General Purpose</li>
                    <li>Low and High Voltage Machines</li>
                    <li>Synchronous and DC Motors</li>
                    <li>ATEX Motors</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsWeg;
