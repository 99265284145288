import React, { Component } from "react";
import  { Link } from "react-router-dom";
class ProductsMechanicalDrives extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <Link to="/contact" className="text-right white"><span className="yellow">Get a Quote →</span></Link>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Mechanical Drives <br />and Components</h2>
              <p className>We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Grove Gear<span className="underline" /></h3>
                  <ul>
                    <li>Right angle and helical gear reducers</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.grovegear.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Shimpo Drives<span className="underline" /></h3>
                  <ul>
                    <li>Ring cone variable speed drives</li>
                    <li>Circulate gearless planetary speed reducers</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.drives.nidec-shimpo.com/en/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Stober Drives<span className="underline" /></h3>
                  <ul>
                    <li>Helical, helical bevel, helical worm and planetary speed reducers</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.stober.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsMechanicalDrives;
