import React, { Component } from "react";
import  { Link } from "react-router-dom";
class ProductsSensors extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
    <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
</header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <Link to="contact" className="text-right white"><span className="yellow">Get a Quote →</span></Link>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Sensors, Logic Controls, <br />Touch Panels &amp; More</h2>
              <p className>We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Banner<span className="underline" /></h3>
                  <ul>
                    <li>Photoelectric, laster, radar and ultrasonic sensors</li>
                    <li>Logic controls</li>
                    <li>Wireless sensors</li>
                    <li>LED lighting, indication, signaling devices and I/O</li>
                    <li>Vision, barcode and measurement sensors</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=banner" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>TURCK<span className="underline" /></h3>
                  <ul>
                    <li>Proximity and capacitive sensors</li>
                    <li>Level controls</li>
                    <li>Cordsets</li>
                    <li>Intrinsically safe barriers</li>
                    <li>Flow monitors, pressure monitors</li>
                    <li>Linear transucers</li>
                    <li>Encoders</li>
                    <li>RFID systems</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=turck" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>RED LION CONTROLS<span className="underline" /></h3>
                  <ul>
                    <li>HMI panels with web server and data collection</li>
                    <li>Counters, rate indicators, controllers, digital panel meters</li>
                    <li>PLC peripherals</li>
                    <li>Signal conditioners</li>
                    <li>Process controllers</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=red+lion" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>PATlite<span className="underline" /></h3>
                  <ul>
                    <li>Signal towers</li>
                    <li>Rotating lights</li>
                    <li>Signal horns and alarms</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.patlite.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Proface/Xycom<span className="underline" /></h3>
                  <ul>
                    <li>Graphic operator interface devices</li>
                    <li>HMI panels</li>
                    <li>Industrial PC's</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.profaceamerica.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>ATC/Diversified Electronics<span className="underline" /></h3>
                  <ul>
                    <li>Motor protection controls</li>
                    <li>Time delay relays</li>
                    <li>Voltage and current monitors</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.marshbellofram.com/diversified-electronics/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Puls<span className="underline" /></h3>
                  <ul>
                    <li>Din rail mounted DC power supplies</li>
                    <li>Single phase and 3 phase</li>
                    <li>DC UPS</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/brands/puls?utf8=%E2%9C%93&search=" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Shimpo American<span className="underline" /></h3>
                  <ul>
                    <li>Hand-held digital tachometers</li>
                    <li>Panel mount tachometers</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.nidec-shimpo.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Horner Automation<span className="underline" /></h3>
                  <ul>
                    <li>All in one controllers with built in logic engine, operator interface,
                      networking and I/O.</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://hornerautomation.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ProductsSensors;
